import { FunctionComponent, ReactNode, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { DependencyAction, DependencyProps } from '../../core/IDependency';
import FormularElement from '../../core/formBuilder/formularElement';

interface ConditionalFieldProps {
  children: ReactNode;
  fieldName: string;
  rulesConfig?: DependencyProps[];
  elements: FormularElement[] | undefined;
}

export const ConditionalField: FunctionComponent<ConditionalFieldProps> = ({
  children,
  fieldName,
  rulesConfig,
  elements,
}) => {
  const { values, initialValues } = useFormState();
  const { mutators } = useForm();

  const [fieldNamesToPrefill, setFieldNamesToPrefill] = useState<Set<string>>(
    new Set()
  );

  const addFieldNameToPrefill = (value?: string): void => {
    if (!value) return;
    if (fieldNamesToPrefill.has(value)) return;
    if (initialValues[value] === undefined) return;
    setFieldNamesToPrefill((prev: Set<string>) => new Set(prev.add(value)));
  };
  const prefillAndRemoveFieldName = (value?: string): void => {
    if (!value) return;
    mutators.setValue(value, initialValues[value], values);
    setFieldNamesToPrefill((prev: Set<string>) => {
      prev.delete(value);
      return new Set(prev);
    });
  };

  if (!rulesConfig || rulesConfig.length < 1) return <>{children}</>;
  let config = rulesConfig[0];

  const handleRule = (superiorValue: string) =>
    isConditionTrue(config.condition, superiorValue)
      ? doAction(config.action, superiorValue)
      : doAction(config.actionNegative, superiorValue);

  function doAction(action: DependencyAction, superiorValue: any) {
    switch (action.effect) {
      case 'show':
        if (
          values[fieldName] === undefined &&
          initialValues[fieldName] &&
          fieldNamesToPrefill.has(fieldName)
        ) {
          prefillAndRemoveFieldName(fieldName);
        } else if (elements?.length) {
          elements
            .filter(
              (el) =>
                el.fieldName &&
                initialValues[el.fieldName] &&
                values[el?.fieldName] === undefined &&
                (initialValues[el.fieldName] ||
                  el.refComponent === 'components/@material/timeComponent')
            )
            .map((el) => el.fieldName)
            .forEach((fieldNameToSetInitValue) => {
              if (
                fieldNameToSetInitValue &&
                fieldNamesToPrefill.has(fieldNameToSetInitValue)
              )
                prefillAndRemoveFieldName(fieldNameToSetInitValue);
            });
        }

        return children;
      case 'hide':
        if (initialValues[fieldName] !== undefined)
          addFieldNameToPrefill(fieldName);
        if (values[fieldName]) {
          mutators.setValue(fieldName, undefined, values);
        } else if (elements?.length) {
          elements
            .filter((el) => el.fieldName)
            .map((el) => el.fieldName)
            .forEach((fieldNameToCleanUp) => {
              if (!fieldNameToCleanUp) return;
              if (values[fieldNameToCleanUp] !== undefined)
                mutators.setValue(fieldNameToCleanUp, undefined, values);
              addFieldNameToPrefill(fieldNameToCleanUp);
            });
        }
        return null;
    }
    return children;
  }

  return (
    <Field name={config.superiorName} subscription={{ value: true }}>
      {({ input: { value } }) => handleRule(value)}
    </Field>
  );
};

export function isConditionTrue(
  condition: any,
  currentValue: string | boolean | number
): boolean {
  let threshold = condition.threshold;
  switch (condition.operator) {
    case '=':
      return currentValue === threshold;
    case '!=':
      return currentValue !== threshold;
    case 'Contains':
      return currentValue.toString().includes(threshold);
    case 'ContainsNot':
      return !currentValue.toString().includes(threshold);
    case '>':
      return isNaN(Number(currentValue))
        ? false
        : Number(currentValue) > Number(threshold);
    case '>=':
      return isNaN(Number(currentValue))
        ? false
        : Number(currentValue) >= Number(threshold);
    case '<':
      return isNaN(Number(currentValue))
        ? false
        : Number(currentValue) < Number(threshold);
    case '<=':
      return isNaN(Number(currentValue))
        ? false
        : Number(currentValue) <= Number(threshold);
    case 'True':
      return Boolean(currentValue);
    case 'False':
      return !Boolean(currentValue);
    case 'Checked':
      return (
        currentValue === true ||
        currentValue.toString().toLowerCase() === 'checked'
      );
    case 'Unchecked':
      return (
        currentValue === false ||
        currentValue.toString().toLowerCase() === 'unchecked'
      );
  }
  return false;
}

export default ConditionalField;
