import React, { FunctionComponent } from 'react';
import Element from './formularElement';
import LazyComponentLoader from '../componentLoading/lazyComponentLoader';
import ElementParser from './elementParser';
import {
  ThirdPartySystemsConfigFb,
  ThirdPartySystemsServiceInstance,
} from '../services/thirdPartySystemsService';

interface ComponentBuilderProps {
  element: Element;
  keys?: Array<number>;
  formThirdPartySystemsConfig?: ThirdPartySystemsConfigFb;
}

const ComponentBuilder: FunctionComponent<ComponentBuilderProps> = ({
  element: {
    refComponent,
    accessibilityConfig,
    thirdPartySystemsConfig,
    elements,
    ...additionalProps
  },
  keys = [],
  formThirdPartySystemsConfig,
}) => {
  return (
    <LazyComponentLoader
      refComponent={refComponent}
      validateFn={ThirdPartySystemsServiceInstance.getValidateFn(
        thirdPartySystemsConfig,
        formThirdPartySystemsConfig
      )}
      {...additionalProps}
      elements={elements}
      accessibilityConfig={accessibilityConfig}
    >
      {elements && elements.length > 0 && (
        <ElementParser
          keys={keys}
          elements={elements}
          formThirdPartySystemsConfig={formThirdPartySystemsConfig}
        />
      )}
    </LazyComponentLoader>
  );
};

export default React.memo(ComponentBuilder);
