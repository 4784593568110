import React, { createContext, FunctionComponent, useState } from 'react';

interface PageState {
  activeStep: number | null;
}

export const actionTypes = {
  next: 'NEXT',
  previous: 'PREVIOUS',
  default: 'DEFAULT',
  set: 'SET',
};

export const PageStepperContext = createContext<{
  activeStep: number | null;
  errorLocated: boolean;
  visitedSteps: Set<number>;
  setVisitedSteps: React.Dispatch<React.SetStateAction<Set<number>>>;
  setErrorLocated: React.Dispatch<React.SetStateAction<boolean>>;
  previousStep: () => void;
  nextStep: () => void;
  defaultStep: () => void;
  setStep: (step: number | null) => void;
}>({
  activeStep: null,
  errorLocated: false,
  visitedSteps: new Set<number>(),
  setVisitedSteps: () => {},
  setErrorLocated: () => {},
  previousStep: () => {},
  nextStep: () => {},
  defaultStep: () => {},
  setStep: (step: number | null) => {},
});

const PageStepperContextProvider: FunctionComponent = ({ children }) => {
  return (
    <PageStepperContext.Provider value={useActiveStep()}>
      {children}
    </PageStepperContext.Provider>
  );
};

function stepReducer(state: PageState, action: any) {
  switch (action.type) {
    case actionTypes.next: {
      return { activeStep: (state.activeStep ?? 0) + 1 };
    }
    case actionTypes.previous: {
      return { activeStep: (state.activeStep ?? 0) - 1 };
    }
    case actionTypes.default: {
      return { activeStep: null };
    }
    case actionTypes.set: {
      return { activeStep: action.step };
    }
    default: {
      throw new Error(`Unhandled type: ${action.type}`);
    }
  }
}

function useActiveStep() {
  const [{ activeStep }, dispatch] = React.useReducer(stepReducer, {
    activeStep: null,
  });

  const [errorLocated, setErrorLocated] = React.useState<boolean>(false);
  const [visitedSteps, setVisitedSteps] = useState(new Set<number>());

  const nextStep = () => {
    dispatch({ type: actionTypes.next });
  };
  const previousStep = () => dispatch({ type: actionTypes.previous });
  const defaultStep = () => {
    dispatch({ type: actionTypes.default });
  };
  const setStep = (step: number | null) =>
    dispatch({
      type: actionTypes.set,
      step: step,
    });

  return {
    activeStep,
    errorLocated,
    visitedSteps,
    setVisitedSteps,
    setErrorLocated,
    nextStep,
    previousStep,
    defaultStep,
    setStep,
  };
}

export default PageStepperContextProvider;
