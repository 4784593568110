/* eslint-disable import/no-anonymous-default-export */
export default {
  MIN_RESTRICTION_TYPE_MAP: {
    disableFuture: () => new Date(),
    disableDatesBeforeToday: () => new Date(),
  },
  MAX_RESTRICTION_TYPE_MAP: {
    disablePast: () => new Date(),
    adult: () => new Date().setFullYear(new Date().getFullYear() - 18),
  },
  BACKEND_URL: (window as any)?._env_?.REACT_APP_BACKEND_URL,

  INSTANCE: (window as any)?._env_?.REACT_APP_INSTANCE,
  PATH_FOR_CUSTOM_STATIC_PAGES: (window as any)?._env_
    ?.REACT_APP_PATH_FOR_CUSTOM_STATIC_PAGES,
  LAYOUT: (window as any)?._env_?.REACT_APP_LAYOUT ?? 'SINGLE',
  ANALYTICS_STRATEGY: (window as any)?._env_?.REACT_APP_ANALYTICS_STRATEGY,
};
